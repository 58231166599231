import { useState, useEffect, RefObject } from 'react';
import { debounce } from 'lodash';
import decideScreenSize, { ScreenSize } from '../utils/decideScreenSize';

export interface IDimensions {
    viewWidth: number;
    viewHeight: number;
    screensize: ScreenSize;
}

const useDimensions = (headerRef?: RefObject<HTMLDivElement>): IDimensions => {
    const [viewWidth, setViewWidth] = useState(0);
    const [viewHeight, setViewHeight] = useState(0);
    const [screensize, setScreenSize] = useState<ScreenSize>(ScreenSize.xs);
    useEffect(() => {
        const handleResize = debounce(
            () => {
                // width
                const { innerWidth } = window;
                setViewWidth(innerWidth);
                // height
                const headerHeigth = headerRef?.current?.clientHeight || 0;
                setViewHeight(window.innerHeight - headerHeigth);
                // screen size
                setScreenSize(decideScreenSize(innerWidth));
            },
            200,
            { leading: false, trailing: true },
        );
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, [headerRef]);
    return { viewWidth, viewHeight, screensize };
};

export default useDimensions;
