import { CSSProperties, MouseEvent, TouchEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSpring } from 'react-spring';
import { IMouseEvents, ITouchEvents } from '../../../hooks/types';
// import decideScreenSize, { ScreenSize } from '../../../utils/decideScreenSize';
import initSpreadedItems, { ICoordinates } from './initSpreadedItems';

interface IParallaxOptions {
    viewWidth: number;
    viewHeight: number;
    maxItems: number;
}

interface IParallaxWrapperProps extends IMouseEvents, ITouchEvents {}

export interface IParallaxCardDimensions {
    width: number;
    expansion: number;
}

interface IUseParallax {
    items: ICoordinates[];
    getStylesForItem: (i: ICoordinates, d: IParallaxCardDimensions) => CSSProperties;
    wrapperProps: IParallaxWrapperProps;
}

export default function useParallax(options: IParallaxOptions): IUseParallax {
    const history = useHistory();
    const { viewWidth, viewHeight, maxItems } = options;
    const [props, set] = useSpring(() => ({
        xy: [0, 0],
        config: { velocity: 1, mass: 1, tension: 500, friction: 70, clamp: true },
    }));
    const [mouseIn, setMouseIn] = useState<boolean>(false);
    const [items, setItems] = useState<ICoordinates[]>([]);
    // const [maxDistance, setMaxDistance] = useState<number>(0);
    const [maxWidthDistance, setMaxWidthDistance] = useState<number>(0);

    useEffect(() => {
        const handleEnter = () => setMouseIn(true);
        document.body.addEventListener('mouseover', handleEnter);
        const handleLeave = () => setMouseIn(false);
        document.body.addEventListener('mouseleave', handleLeave);
        return () => {
            document.body.removeEventListener('mouseover', handleEnter);
            document.body.removeEventListener('mouseleave', handleLeave);
        };
    }, []);

    useEffect(() => {
        if (viewWidth <= 0 || viewHeight <= 0 || maxItems <= 0) {
            return;
        }
        setItems(initSpreadedItems(viewWidth, viewHeight, maxItems));
        const maxDistance = Math.sqrt(viewWidth * viewWidth + viewHeight * viewHeight);
        // setMaxDistance(maxDistance);
        setMaxWidthDistance(maxDistance / 4);
    }, [viewWidth, viewHeight, maxItems]);

    useEffect(() => {
        return history.listen(() => setItems(initSpreadedItems(viewWidth, viewHeight, maxItems)));
    }, [history, maxItems, viewHeight, viewWidth]);

    const setData = (x: number, y: number) => set({ xy: [x - viewWidth / 2, y - viewHeight / 2] });

    const onMouseMove = ({ clientX, clientY }: MouseEvent<HTMLDivElement>) => setData(clientX, clientY);

    const onTouch = (e: TouchEvent) => {
        const { clientX, clientY } = e.nativeEvent.touches[0];
        setData(clientX, clientY);
        setMouseIn(true);
    };

    const onTouchEnd = () => {
        setMouseIn(false);
    };

    return {
        items,
        wrapperProps: {
            onMouseMove,
            onTouchStart: onTouch,
            onTouchMove: onTouch,
            onTouchEnd,
        },
        getStylesForItem: (i, d) =>
            getStylesForItem(
                i,
                d,
                props,
                // maxDistance,
                maxWidthDistance,
                mouseIn,
                // decideScreenSize(viewWidth) === ScreenSize.xs,
            ),
    };
}

const getStylesForItem = (
    i: ICoordinates,
    dimensions: IParallaxCardDimensions,
    props: unknown,
    // maxDistance: number,
    maxWidthDistance: number,
    mouseIn: boolean,
    // simulateZindex: boolean,
): CSSProperties => {
    const { width: cardWidth, expansion: cardExpansion } = dimensions;
    const decideTransform = (x: number, y: number) => {
        return `translate3d(${i.x - x / 4}px,${i.y - y / 4}px,0)`;
    };

    const decideWidth = (x: number, y: number) => {
        if (!mouseIn) {
            return `${cardWidth}% `;
        }
        const a = x - i.x;
        const b = y - i.y;
        const distance = Math.sqrt(a * a + b * b);
        if (distance > maxWidthDistance) {
            return `${cardWidth}% `;
        }
        if (distance < maxWidthDistance / 20) {
            return `${cardWidth + cardExpansion}% `;
        }
        const ratio = (distance - maxWidthDistance / 20) / maxWidthDistance;
        const expansion = cardExpansion * (1 - ratio);
        const width = cardWidth + expansion;
        return `${width}% `;
    };

    // const decideZindex = (x: number, y: number) => {
    //     const a = x - i.x;
    //     const b = y - i.y;
    //     const distance = Math.sqrt(a * a + b * b);
    //     const ratio = distance / maxDistance;
    //     const index = (ratio * 100 - 100) * -1;
    //     return Math.floor(index);
    // };

    return {
        // @ts-ignore
        transform: props.xy.interpolate(decideTransform),
        // @ts-ignore
        width: props.xy.interpolate(decideWidth),
        // @ts-ignore
        // zIndex: simulateZindex ? props.xy.interpolate(decideZindex) : undefined,
    };
};
