import { CSSProperties } from 'react';
import { IUseLoopState } from './initState';

/**
 * Calculate styles for index
 * @param index
 * @param state
 */
export default function decideStylesByIndex(index: number, state: IUseLoopState): CSSProperties {
    const isVisisble = __isEelementVisible(index, state);
    const top = __decidePositionByIndex(index, state);
    const opacity = isVisisble ? 1 : 0;
    const transition = isVisisble ? 'top 700ms ease' : 'none';
    const zIndex = 0;
    return { top, opacity, zIndex, height: state.heights[index]?.height || 0, transition };
}

const __isEelementVisible = (index: number, state: IUseLoopState): boolean => {
    const { currentPage, maxPages, snapDirection } = state;
    // current page
    if (index === currentPage) {
        return true;
    }
    // previous
    if (index === currentPage - 1 || (currentPage === 0 && index === maxPages - 1)) {
        return snapDirection !== 'down';
    }
    // next
    if (index === currentPage + 1 || (currentPage === maxPages - 1 && index === 0)) {
        return snapDirection !== 'up';
    }
    return false;
};

const __decidePositionByIndex = (index: number, state: IUseLoopState): number => {
    const { currentPage, scrolled, maxPages, heights } = state;
    const { height = 0, offset = 0 } = heights[currentPage] || {};
    const { height: affectedHeight = 0 } = heights[index] || {};
    // current
    if (index === currentPage) {
        return -scrolled + offset;
    }
    // next
    if (index === currentPage + 1 || (index === 0 && currentPage === maxPages - 1)) {
        return -scrolled + offset + height;
    }
    return -affectedHeight;
};
