import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import routes from '.';
import NotFound from './NotFound';
import PageTransition from './PageTransition';

const Routes = (): JSX.Element => {
    const location = useLocation();
    return (
        <Switch location={location}>
            {routes.map((item, idx) => (
                <Route key={idx} exact path={item.path}>
                    <PageTransition in component={item.component} />
                </Route>
            ))}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
};

export default Routes;
