import {
    IProject,
    IProjectModule,
    IProjectColumnsModule,
    IProjectModuleImage,
} from '../../../contentful/contentful-types.d';
import getRandomItems from '../../../utils/getRandomItems';
import { ICard } from '../hook/useCards';

export default function chooseCards(projects: IProject[], maxItems: number): ICard[] {
    // choose projects
    const choosenProjects = getRandomItems<IProject>(projects, 20);
    // find images by project
    let images: ICard[] = [];
    choosenProjects.forEach((project: IProject) => (images = [...images, ...prepareProjectImages(project, maxItems)]));
    return images;
}

const prepareProjectImages = (project: IProject, maxItems: number) => {
    const { modules } = project;
    // find all images
    let pImages: ICard[] = [];
    modules.forEach((module) => (pImages = [...pImages, ...imagesByModule(project, module)]));
    // filter images
    pImages = pImages.filter((img) => img.image.homepage);
    pImages = getRandomItems<ICard>(pImages, maxItems);
    return pImages;
};

const imagesByModule = (project: IProject, module: IProjectModule | IProjectColumnsModule): ICard[] => {
    // images for cards
    let images: ICard[] = [];

    // single image module
    const singleModule = module as IProjectModule;
    if (singleModule.image !== undefined) {
        const { image } = singleModule;
        images = [...images, ...[{ project, image, isLandscape: isLandscape(image) }]];
    }

    // columns module
    const columnModule = module as IProjectColumnsModule;
    if (columnModule.column1 !== undefined) {
        const data: ICard[] = columnModule.column1.map((image) => ({
            project,
            image,
            isLandscape: isLandscape(image),
        }));
        images = [...images, ...data];
    }

    if (columnModule.column2 !== undefined) {
        const data: ICard[] = columnModule.column2.map((image) => ({
            project,
            image,
            isLandscape: isLandscape(image),
        }));
        images = [...images, ...data];
    }

    return images;
};

const isLandscape = (image: IProjectModuleImage) => {
    if (!image?.image?.file) {
        return true;
    }

    const {
        image: { file },
    } = image;
    const {
        details: {
            image: { height, width },
        },
    } = file;
    return width > height;
};
