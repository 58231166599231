import React, { FunctionComponent } from 'react';
import { useContentfulData } from '../../contentful';
import { IContact } from '../../contentful/contentful-types.d';
import { ScreenSize } from '../../utils/decideScreenSize';
import Spinner from '../Spinner/Spinner';
import styles from './ContactAccordion.module.scss';
import ContactBlock from './ContactBlock';
import CreditsBlock from './CreditsBlock';

interface props {
    screensize: ScreenSize;
}

const ContactAccordion: FunctionComponent<props> = () => {
    const { items, ...rest } = useContentfulData<IContact>({
        contentType: 'website',
        query: { limit: 1 },
    });

    return (
        <Spinner {...rest}>
            {items.length > 0 && (
                <div className={styles['ContactAccordion']}>
                    <ContactBlock {...items[0]} />
                    <CreditsBlock {...items[0]} />
                </div>
            )}
        </Spinner>
    );
};

export default ContactAccordion;
