import React, { FunctionComponent, memo } from 'react';
import { IProjectModule } from '../../contentful/contentful-types.d';
import { ScreenSize } from '../../utils/decideScreenSize';
import ProjectImage from '../Image/Image';
import moduleSingleStyles from './styles/ModuleSingle.module.scss';

const styleSingle = 'ModuleSingle';
const styleSingleColumn = `${styleSingle}-column`;

interface IProps extends IProjectModule {
    availableHeight?: number;
    screenSize?: ScreenSize;
    getLost: boolean;
    loadImages: boolean;
}

const ProjectModuleSingle: FunctionComponent<IProps> = (props) => {
    const { image, availableHeight, screenSize, getLost, loadImages } = props;
    if (!image) {
        return null;
    }

    const maxHeight = availableHeight && screenSize ? decideMaxHeight(availableHeight, screenSize, getLost) : undefined;
    return (
        <div className={moduleSingleStyles[styleSingleColumn]}>
            <ProjectImage
                w={screenSize === ScreenSize.xs ? 1000 : undefined}
                load={loadImages}
                maxHeight={maxHeight ? `${maxHeight}px` : undefined}
                {...image}
            />
        </div>
    );
};

const decideMaxHeight = (availableHeight: number, screenSize: ScreenSize, getLost: boolean) => {
    if (!getLost || screenSize === ScreenSize.xl) {
        return availableHeight;
    }
    // if (screenSize === ScreenSize.sm) {
    //     return availableHeight - 28;
    // }
    return availableHeight - 35;
};

const ProjectModuleSingleMemo = memo(ProjectModuleSingle);
export { ProjectModuleSingleMemo };
export default ProjectModuleSingle;
