import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { IImage } from '../../contentful/contentful-types.d';
import Link from '../Link/Link';
import styles from './AboutBlocks.module.scss';

interface props {
    header: string;
    tagSearch?: string;
    children: ReactNode;
    colored?: boolean;
    hoverImage?: IImage;
}

const Split: FunctionComponent<props> = (props) => {
    const { header, tagSearch, children, colored, hoverImage } = props;
    const [hover, setHover] = useState<boolean>(false);

    const headerStyles = classnames(styles['BlockHeader'], {
        [styles['BlockHeader--colored']]: colored === true,
        [styles['BlockHeader--linked']]: hoverImage !== undefined,
    });

    return (
        <div className={styles['Block']}>
            <h2 className={headerStyles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                {header}
                {tagSearch && (
                    <Link path={'/archive'} search={tagSearch}>
                        <>See Photos</>
                    </Link>
                )}
            </h2>

            {hover && hoverImage && (
                <div className="hover-image">
                    <span>
                        <img src={hoverImage.file.url} alt={hoverImage.title} />
                    </span>
                </div>
            )}
            <div className={styles['BlockContent']}>
                <span>{children}</span>
            </div>
        </div>
    );
};

export default Split;
