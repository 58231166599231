import React, { FunctionComponent } from 'react';

import { useContentfulData } from '../../contentful';
import { IAboutBlock } from '../../contentful/contentful-types.d';

import AboutBlock from './AboutBlock';

import styles from './AboutBlocks.module.scss';
import Spinner from '../Spinner/Spinner';
import RichCopy from '../RichText';

const AboutBlocks: FunctionComponent = () => {
    const { items, ...rest } = useContentfulData<IAboutBlock>({
        contentType: 'aboutBlock',
        query: { order: 'fields.priority' },
    });
    return (
        <div className={styles['Blocks']}>
            <Spinner {...rest}>
                {items.map((block: IAboutBlock, idx: number) => {
                    const { label, tagSearch, content, contentMarkdown } = block;
                    return (
                        <AboutBlock
                            key={idx}
                            header={label}
                            tagSearch={tagSearch ? `?search=${tagSearch}` : undefined}
                            colored
                        >
                            {contentMarkdown ? <RichCopy markdown={contentMarkdown} /> : content}
                        </AboutBlock>
                    );
                })}
            </Spinner>
        </div>
    );
};

export default AboutBlocks;
