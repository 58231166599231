import React, { FunctionComponent } from 'react';
import { IContact, IImage } from '../../contentful/contentful-types.d';
import { AboutBlock } from '../AboutBlocks';

const ContactBlock: FunctionComponent<IContact> = (props) => {
    const {
        shortInfo,
        email,
        phoneSpain,
        phoneFrance,
        instagram,
        facebook,
        vimeo,
        emailImage,
        socialMediaImage,
        contactImage,
    } = props;
    return (
        <AboutBlock header="CONTACT" hoverImage={contactImage}>
            <ul>
                <li>{shortInfo}</li>
                <li>
                    {linkWithImage(email, `mailto:${email}`, emailImage)}
                    <br />
                    {phoneSpain && <a href={`tel:${phoneSpain}`}>{phoneSpain}</a>}
                    <br />
                    {phoneFrance && <a href={`tel:${phoneFrance}`}>{phoneFrance}</a>}
                </li>
                <li>
                    {instagram && linkWithImage('Instagram', instagram, socialMediaImage)}
                    <br />
                    {facebook && linkWithImage('Facebook', facebook, socialMediaImage)}
                    <br />
                    {vimeo && linkWithImage('Vimeo', vimeo)}
                </li>
            </ul>
        </AboutBlock>
    );
};

const linkWithImage = (label: string, link: string, image?: IImage) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {label}
            {image && (
                <span>
                    <img src={image.file.url} alt={image.title} />
                </span>
            )}
        </a>
    );
};

export default ContactBlock;
