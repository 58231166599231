import qs from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContentfulData } from '../../../contentful';
import { IProjectModuleImage } from '../../../contentful/contentful-types.d';

interface IUseArchiveSearch {
    search: string;
    personalItems: IProjectModuleImage[];
    personalMore?: () => void;
    commissionedItems: IProjectModuleImage[];
    commissionedMore?: () => void;
    filteredItems: IProjectModuleImage[];
    filteredMore?: () => void;
    tags: string;
    setTags: (tags: string) => void;
}

export default function useArchiveSearch(): IUseArchiveSearch {
    // initial query
    const query = useRef({ limit: 50 });

    // tags state
    const history = useHistory();
    const search = new URLSearchParams(history.location.search).get('search') || '';
    const [tags, setTags] = useState<string>(search);

    // personal fetch
    const { items: personalItems, loadMore: personalMore } = useContentfulData<IProjectModuleImage>({
        contentType: 'moduleImage',
        query: { ...query.current, ...{ 'fields.archiveType': 'personal' } },
    });

    // commissioned fetch
    const { items: commissionedItems, loadMore: commissionedMore } = useContentfulData<IProjectModuleImage>({
        contentType: 'moduleImage',
        query: { ...query.current, ...{ 'fields.archiveType': 'commissioned' } },
    });

    // all fetch
    const { items: filteredItems, loadMore: filteredMore, fetchWithQuery } = useContentfulData<IProjectModuleImage>({
        contentType: 'moduleImage',
        query: { ...query.current, ...{ 'fields.archiveTags[match]': tags } },
    });

    // fetch tags
    const handleSetTags = (newTags: string) => {
        setTags(newTags);
        history.push({ search: qs.stringify({ search: newTags }) });
    };

    const fetch = useCallback(fetchWithQuery, []);
    useEffect(() => fetch({ ...query.current, ...{ 'fields.archiveTags[match]': tags } }), [tags, query, fetch]);

    return {
        search,
        personalItems,
        personalMore,
        commissionedItems,
        commissionedMore,
        filteredItems,
        filteredMore,
        tags,
        setTags: handleSetTags,
    };
}
