import { ContentfulClient } from 'react-contentful';

// @ts-ignore
const client = new ContentfulClient({
    accessToken: process.env.REACT_APP_CONTENTFULL_ACCESS_TOKEN as string,
    space: process.env.REACT_APP_CONTENTFULL_SPACE_ID as string,
    environment: process.env.REACT_APP_CONTENTFULL_ENVIRONMENT as string,
});

export default client;
