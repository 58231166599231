import React, { FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';

const duration = 500;

interface props {
    in: boolean;
    component: FunctionComponent;
}

const PageTransition: FunctionComponent<props> = (props) => {
    const { in: inProp, component: Component } = props;
    return (
        <CSSTransition appear in={inProp} timeout={duration} classNames="fade">
            <div>
                <Component />
            </div>
        </CSSTransition>
    );
};

export default PageTransition;
