import React, { FunctionComponent } from 'react';
import { IProjectModule, ISubSequenceModule } from '../../contentful/contentful-types.d';
import useCursor from '../../hooks/useCursor';
import ProjectLink from '../../routes/Project/ProjectLink';
import { ScreenSize } from '../../utils/decideScreenSize';
import ProjectModuleSingle from '../Project/ProjectModuleSingle';
import styles from './SubSequence.module.scss';
import decideOpacity from './utils/decideOpacity';

interface ModuleProps {
    currentPage: number;
    module: ISubSequenceModule;
    maxPages: number;
    reversed: boolean;
    screenSize: ScreenSize;
}

const SubSequenceModule: FunctionComponent<ModuleProps> = (props) => {
    const { currentPage, module, maxPages, reversed, screenSize } = props;
    const { in: elementIn, fadeIn, out: elementOut, fadeOut, backToProject, backToProjectPage } = module;
    const isBackToPath = backToProject ? true : false;
    const opacity = decideOpacity(maxPages, reversed, currentPage, elementIn, fadeIn, elementOut, fadeOut);
    // const loadImages = opacity > 0;
    const loadImages = true;
    const { onMouseMove, cursorRef } = useCursor();

    const renderInner = (loadImages: boolean) => (
        <ProjectModuleSingle
            loadImages={loadImages}
            getLost={isBackToPath}
            screenSize={screenSize}
            {...(module as IProjectModule)}
        />
    );

    const renderLink = (loadImages: boolean, projectPath: string, projectPage?: number) => (
        <>
            <ProjectLink
                onMouseMove={onMouseMove}
                onMouseOver={onMouseMove}
                className={styles['SubSequence-backToPath']}
                path={projectPath}
                page={projectPage}
            >
                <>
                    {renderInner(loadImages)}
                    <span ref={cursorRef} className={styles['SubSequence-backToPath-cursor']}>
                        Back to series
                    </span>
                </>
            </ProjectLink>
            <ProjectLink className={styles['SubSequence-backToPath-footer']} path={projectPath} page={projectPage}>
                <span>Back to series</span>
            </ProjectLink>
        </>
    );

    return (
        <div
            className={styles['SubSequence-module']}
            style={{
                opacity,
                display: opacity === 0 ? 'none' : 'block',
                zIndex: isBackToPath ? 50 : undefined,
            }}
        >
            {backToProject ? renderLink(loadImages, backToProject, backToProjectPage) : renderInner(loadImages)}
        </div>
    );
};

export default SubSequenceModule;
