import { IUseLoopState, ElementHeight } from './initState';

/**
 * Calculate new state based on new scrolled offset
 * @param deltaY
 * @param state
 */
export default function recalculateScroll(offset: number, state: IUseLoopState): IUseLoopState {
    const { scrolled, cycleHeight, heights, currentPage } = state;
    // calculate scroll position
    const cleanScrolled = __calculateScrolled(scrolled, offset, cycleHeight);
    // item scroll
    const currentItem = heights.find((el) => __isCurrentPage(cleanScrolled, el));
    return {
        ...state,
        scrolled: cleanScrolled,
        currentPage: currentItem ? currentItem.index : currentPage,
    };
}

const __calculateScrolled = (initial: number, offset: number, cycleHeight: number): number => {
    // calculate scroll position
    const newScrolled = initial + offset;
    // scrolling to previous cycle
    if (newScrolled < 0) {
        return newScrolled + cycleHeight;
    }
    // scrolling to new cycle
    if (newScrolled >= cycleHeight) {
        return newScrolled - cycleHeight;
    }
    return newScrolled;
};

const __isCurrentPage = (scrolled: number, data: ElementHeight): boolean => {
    const { height, offset } = data;
    return scrolled >= offset && scrolled < height + offset;
};
