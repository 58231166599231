import classnames from 'classnames';
import React, { FunctionComponent, memo, useLayoutEffect, useState } from 'react';

import { IProject, IProjectModule } from '../../contentful/contentful-types.d';
import HomeLink from '../../routes/Home/HomeLink';
import SubSequenceLink from '../../routes/SubSequence/SubSequenceLink';
import CloseButton from '../CloseButton/CloseButton';
import styles from './styles/Header.module.scss';

interface Props extends IProject {
    currentPage?: number;
}

const ProjectHeader: FunctionComponent<Props> = (props) => {
    const { title, currentPage, modules, path } = props;
    const [visible, setVisible] = useState(false);

    const currentModule = currentPage !== undefined ? modules?.[currentPage] : null;
    const module = currentModule as IProjectModule;
    const { subSequence, reversed } = module || {};

    const headerClass = classnames(styles['ProjectHeader'], {
        [styles['ProjectHeader-visible']]: visible,
    });

    useLayoutEffect(() => {
        setTimeout(() => setVisible(true), 200);
    }, []);

    return (
        <header className={headerClass}>
            <span className={styles['ProjectHeader-title']}>{title}</span>
            <span className={styles['ProjectHeader-counter']}>
                {currentPage !== undefined && `${currentPage + 1}  / ${modules.length}`}
            </span>
            {subSequence && (
                <SubSequenceLink
                    className={styles['ProjectHeader-get-lost']}
                    path={subSequence}
                    state={{ referer: path, page: currentPage, reversed }}
                >
                    <span>Get lost</span>
                </SubSequenceLink>
            )}
            <CloseButton>
                <HomeLink />
            </CloseButton>
        </header>
    );
};

export default memo(ProjectHeader);
