import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { IProject } from '../../contentful/contentful-types.d';
import useCards from './hook/useCards';
import chooseCards from './utils/chooseCards';
import { Parallax } from '../Parallax';
import Card from './Card';
import styles from './Cards.module.scss';

interface ProjectProps {
    items: IProject[];
}

const Cards: FunctionComponent<ProjectProps> = (props) => {
    const { items } = props;
    const chooseCardsCB = useCallback((maxItems: number) => chooseCards(items, maxItems), [items]);
    const {
        viewWidth,
        viewHeight,
        cards,
        cardDimensions,
        projectTransitioning,
        projectTransition,
        setProjectTransition,
    } = useCards(chooseCardsCB);

    const className = classnames(styles['Cards'], {
        [styles['Cards--exit']]: projectTransitioning,
    });

    const decideCardDimensions = (idx: number) => {
        const card = cards != null ? cards[idx] : null;
        if (card && card.isLandscape) {
            return {
                width: cardDimensions.landscape,
                expansion: cardDimensions.landscapeExpansion,
            };
        }
        return {
            width: cardDimensions.portrait,
            expansion: cardDimensions.portraitExpansion,
        };
    };

    return cards != null ? (
        <Parallax
            className={className}
            cardClassName={styles['Card']}
            cardsCount={cards.length}
            getCardDimensions={decideCardDimensions}
            renderCard={(idx) => (
                <Card
                    key={idx}
                    {...cards[idx]}
                    selectedProject={projectTransition}
                    selectProject={setProjectTransition}
                />
            )}
            viewWidth={viewWidth}
            viewHeight={viewHeight}
        />
    ) : null;
};

export default Cards;
