import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './Parallax.module.scss';

interface ParallaxCardProps {
    portrait: boolean;
    transparent: boolean;
    children: ReactNode;
}

const ParallaxCard: FunctionComponent<ParallaxCardProps> = (props) => {
    const { portrait, transparent, children } = props;
    const componentClass = classNames(styles['ParallaxCard-inner'], {
        [styles['ParallaxCard-portrait']]: portrait,
        [styles['ParallaxCard-landscape']]: !portrait,
        [styles['ParallaxCard-transparent']]: transparent,
    });
    return <div className={componentClass}>{children}</div>;
};

export default ParallaxCard;
