import { MouseEvent, useEffect, useRef, useState, MouseEventHandler, RefObject, TouchEventHandler } from 'react';

interface IUseCursor {
    onMouseMove: MouseEventHandler;
    onTouch?: TouchEventHandler;
    cursorRef: RefObject<HTMLElement>;
    isVisible: boolean;
}

export default function useCursor(): IUseCursor {
    const cursorRef = useRef<HTMLSpanElement>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [state, setState] = useState({ mouseX: 0, mouseY: 0 });
    const onMouseMove = (evt: MouseEvent) => {
        const { clientX: mouseX, clientY: mouseY } = evt;
        setState({ mouseX, mouseY });
    };

    useEffect(() => {
        const { mouseX, mouseY } = state;
        if (cursorRef.current) {
            cursorRef.current.style.transform = `translate3d(${mouseX - 15}px, ${mouseY + 10}px, 0)`;
            cursorRef.current.style.opacity = '1';
            setIsVisible(true);
        }
    }, [state]);

    return {
        onMouseMove,
        cursorRef,
        isVisible,
    };
}
