import classnames from 'classnames';
import React, { FunctionComponent, MouseEvent } from 'react';
import { IProjectModuleImage } from '../../contentful/contentful-types.d';
import ImageResult from './ImageResult';
import styles from './Search.module.scss';

interface props {
    className?: string;
    header?: string;
    search: string;
    type: string;
    items: IProjectModuleImage[];
    fullscreen?: boolean;
    openGallery: (type: string, index: number) => void;
    loadMore?: () => void | undefined;
}

const ImageSearch: FunctionComponent<props> = (props) => {
    const { className, header, search, items, type, fullscreen = false, openGallery, loadMore } = props;

    const searchClass = classnames(styles['Search'], className, {
        [styles['Search--fullscreen']]: fullscreen,
    });

    const onClick = (idx: number) => (e: MouseEvent) => {
        e.preventDefault();
        openGallery(type, idx);
    };

    return (
        <div className={searchClass}>
            {header && <h2 className={styles['SearchHeader']}>{header}</h2>}

            <div className={styles['SearchResults']}>
                {items.length > 0 &&
                    items.map((image, idx) => <ImageResult key={idx} onClick={onClick(idx)} {...image} />)}

                {items.length <= 0 && (
                    <div className={styles['SearchNoResults']}>Sorry, no matches found for: {search}</div>
                )}
            </div>

            {loadMore && (
                <div className={styles.LoadMore} onClick={() => loadMore()}>
                    LOAD MORE
                </div>
            )}
        </div>
    );
};

export default ImageSearch;
