import { useEffect, useState } from 'react';

export default function useBackgroundIndex(): number {
    const [bgIndex, setBgIndex] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setBgIndex((bgIndex) => (bgIndex >= 4 ? 1 : bgIndex + 1));
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    return bgIndex;
}
