import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ISubSequence } from '../../contentful/contentful-types.d';

import SubSequence from '../../components/SubSequence';
import { useContentfulData } from '../../contentful';
import Spinner from '../../components/Spinner/Spinner';

interface props {
    path: string;
}

export interface ISubSequenceState {
    referer?: string;
    page?: number;
    reversed?: boolean;
}

const SubSequencePage: FunctionComponent = () => {
    // read path and state
    const { path } = useParams<props>();
    const { state } = useLocation<ISubSequenceState>();

    // fetch data
    const { items, ...rest } = useContentfulData<ISubSequence>({
        contentType: 'subSequence',
        query: {
            'fields.path': path,
        },
    });

    const subSequence = items.length > 0 ? items[0] : null;

    return <Spinner {...rest}>{subSequence && <SubSequence {...state} {...subSequence} />}</Spinner>;
};

export default SubSequencePage;
