import React, { FunctionComponent, MouseEvent } from 'react';
import { ParallaxCard } from '../Parallax';
import styles from './Cards.module.scss';
import { ICard } from './hook/useCards';

const Card: FunctionComponent<ICard> = (props) => {
    const { project, image, selectedProject, selectProject } = props;

    if (!image?.image?.file) {
        return null;
    }

    const {
        image: { file, title },
    } = image;

    const {
        url,
        details: {
            image: { height, width },
        },
    } = file;

    const onClick = (e: MouseEvent) => {
        e.preventDefault();
        if (selectProject) {
            selectProject(project);
        }
    };

    const portrait = width <= height;
    const transparent: boolean = selectedProject !== null && project !== selectedProject;

    return (
        <ParallaxCard portrait={portrait} transparent={transparent}>
            <a href={`/series/${project.path}`} onClick={onClick}>
                <img className={styles['CardImage']} src={`${url}?w=400`} alt={title} />
            </a>
        </ParallaxCard>
    );
};

export default Card;
