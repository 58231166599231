import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import styles from './CloseButton.module.scss';

interface Props {
    className?: string;
    children?: ReactNode;
}

const CloseButton: FunctionComponent<Props> = (props) => {
    const { className, children } = props;
    const wrapperClass = classnames([styles['CloseButton']], className);
    return <span className={wrapperClass}>{children}</span>;
};

export default CloseButton;
