export default function getRandomItems<T>(items: T[], max: number): T[] {
    // more elements than available
    let { length } = items;
    if (max >= length) {
        return items;
    }

    // randomly choose
    const taken = new Array(length);
    const result = new Array(max);
    while (max--) {
        const x = Math.floor(Math.random() * length);
        result[max] = items[x in taken ? taken[x] : x];
        taken[x] = --length in taken ? taken[length] : length;
    }

    return result;
}
