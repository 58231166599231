export default function decideOpacity(
    maxPages: number,
    reversed: boolean,
    pCcurrentPage: number,
    start = 0,
    fadeIn?: number,
    end?: number,
    fadeOut?: number,
): number {
    const currentPage = reversed ? maxPages - pCcurrentPage : pCcurrentPage;

    // still not visible
    if (currentPage < start) {
        return 0;
    }

    // already passed
    if (end !== undefined && currentPage >= end) {
        return 0;
    }
    // fadding in
    if (fadeIn !== undefined && start <= currentPage && start + fadeIn > currentPage) {
        return calculateFadeIn(start, start + fadeIn, currentPage);
    }
    // fadding out
    if (end !== undefined && fadeOut !== undefined && end > currentPage && end - fadeOut <= currentPage) {
        return calculateFadeOut(end - fadeOut, end, currentPage);
    }
    return 1;
}

const calculateFadeIn = (start: number, end: number, current: number) => {
    if (current < start) {
        return 0;
    }
    if (current > end) {
        return 1;
    }
    const range = end - start;
    const level = current - start;
    return level / range;
};

const calculateFadeOut = (start: number, end: number, current: number) => {
    const fadeIn = calculateFadeIn(start, end, current);
    return fadeIn === 0 ? 1 : 1 - fadeIn;
};
