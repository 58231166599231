import { useState, useRef, useEffect } from 'react';

interface IUseArchiveOptions {
    tags: string;
}

interface IUseArchiveUX {
    showMain: boolean;
    showResults: boolean;
}

export default function useArchiveUX(options: IUseArchiveOptions): IUseArchiveUX {
    const { tags } = options;
    const [showResults, setShowResults] = useState(false);
    const [transitioning, setTransitioning] = useState<string | null>(null);
    const transitionTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const hasTags = tags.length > 0;
        if (hasTags && !showResults && transitioning !== 'show') {
            setShowResults(hasTags);
            setTransitioning('show');
            if (transitionTimeout.current) {
                clearTimeout(transitionTimeout.current);
                transitionTimeout.current = setTimeout(() => setTransitioning(null), 500);
            }
        }

        if (!hasTags && showResults && transitioning !== 'hide') {
            setShowResults(false);
            setTransitioning('hide');
            if (transitionTimeout.current) {
                clearTimeout(transitionTimeout.current);
                transitionTimeout.current = setTimeout(() => setTransitioning(null), 500);
            }
        }

        return () => {
            if (transitionTimeout.current) {
                clearTimeout(transitionTimeout.current);
            }
        };
    }, [transitionTimeout, tags, showResults, transitioning]);

    const shouldShowResults = (showResults && transitioning === null) || (showResults && transitioning === 'show');

    return {
        showMain: !shouldShowResults,
        showResults: shouldShowResults,
    };
}
