import React, { FC } from 'react';

import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RichCopy: FC<{ markdown: any }> = (props) => {
    const { markdown } = props;
    if (!markdown.nodeType) {
        return null;
    }

    const options: Options = {
        // renderMark: {
        //     // eslint-disable-next-line
        //     [MARKS.BOLD]: (text: any) => <Copy tag="strong">{text}</Copy>,
        // },
        renderText: (text: string) => {
            // sometimes contenful converts line breaks to empty strings.
            if (text.length <= 0) {
                return '';
            }
            return text
                .replace(/\u2028/g, '')
                .split('\n')
                .flatMap((text: string, i: number) => [i > 0 && <br key={i} />, text]);
        },
        renderNode: {
            // eslint-disable-next-line
            [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
                <div>{children}</div>
            ),
            // eslint-disable-next-line
            [INLINES.HYPERLINK]: (node: any, children: any) => (
                <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            ),
        },
    };
    return <>{documentToReactComponents(markdown, options)}</>;
};

export default RichCopy;
