import classnames from 'classnames';
import React, { FunctionComponent, memo } from 'react';
import { IProjectColumnsModule, IProjectModule } from '../../contentful/contentful-types.d';
import useCursor from '../../hooks/useCursor';
import SubSequenceLink from '../../routes/SubSequence/SubSequenceLink';
import decideScreenSize, { ScreenSize } from '../../utils/decideScreenSize';
import ProjectModuleColumn from './ProjectModuleColumn';
import ProjectModuleSingle from './ProjectModuleSingle';
import styles from './styles/Module.module.scss';

interface IProps {
    projectPath: string;
    moduleIndex: number;
    availableWidth: number;
    availableHeight: number;
    item: IProjectModule | IProjectColumnsModule;
    loadImages: boolean;
    showCursor?: boolean;
}

const stylesModule = 'ProjectModule';
const ProjectModule: FunctionComponent<IProps> = (props) => {
    const { projectPath, moduleIndex, availableWidth, availableHeight, item, loadImages, showCursor = true } = props;
    const { subSequence, reversed = false } = item;
    const screenSize: ScreenSize = decideScreenSize(availableWidth);

    const { onMouseMove, isVisible, cursorRef } = useCursor();
    const className = classnames(styles[stylesModule], {
        [styles['ProjectModule--getLost']]: subSequence,
        [styles['ProjectModule--getLost--effects']]: subSequence && isVisible,
    });

    const renderInner = () => (
        <>
            {item.contentType === 'module' && (
                <ProjectModuleSingle
                    getLost={subSequence ? true : false}
                    screenSize={screenSize}
                    availableHeight={availableHeight}
                    loadImages={loadImages}
                    {...(item as IProjectModule)}
                />
            )}
            {item.contentType === 'columnsModule' && (
                <ProjectModuleColumn
                    screenSize={screenSize}
                    availableWidth={availableWidth}
                    availableHeight={availableHeight}
                    loadImages={loadImages}
                    {...(item as IProjectModule)}
                />
            )}
            {showCursor && (
                <span ref={cursorRef} className={styles.ProjectModuleCursor}>
                    Get lost
                </span>
            )}
        </>
    );

    if (subSequence) {
        return (
            <SubSequenceLink
                className={className}
                path={subSequence}
                state={{ referer: projectPath, page: moduleIndex, reversed }}
                onMouseMove={onMouseMove}
                onMouseOver={onMouseMove}
            >
                {renderInner()}
            </SubSequenceLink>
        );
    }
    return <div className={className}>{renderInner()}</div>;
};

export default memo(ProjectModule);
