import React, { FunctionComponent, ReactNode } from 'react';
import { ContentfulSpinner } from '../../contentful/contentful-types.d';
import styles from './Spinner.module.scss';

interface SpinnerProps extends ContentfulSpinner {
    children?: ReactNode;
}

const Spinner: FunctionComponent<SpinnerProps> = (props) => {
    const { error, loading, fetched, children } = props;
    return (
        <>
            {error && <div className={styles['Spinner']}>{error.message}</div>}
            {loading && !error && <div className={styles['Spinner']}>Loading...</div>}
            {fetched && children}
        </>
    );
};

export default Spinner;
