import classnames from 'classnames';
import React, { FC } from 'react';
import { IProjectModuleImage } from '../../contentful/contentful-types.d';
import { ScreenSize } from '../../utils/decideScreenSize';
import styles from './Gallery.module.scss';

interface Props extends IProjectModuleImage {
    screenSize?: ScreenSize;
}

const GallerySlide: FC<Props> = (props) => {
    if (!props.image?.file) {
        return null;
    }

    const {
        image: { file, title },
        screenSize,
    } = props;

    const {
        url,
        details: {
            image: { height, width },
        },
    } = file;

    const landscape: boolean = width > height;
    const slideImageStyles = classnames([styles['GallerySlideImage']], {
        [styles['GallerySlideImage-landscape']]: landscape,
        [styles['GallerySlideImage-portrait']]: !landscape,
    });

    const imageUrl = screenSize === ScreenSize.xs ? `${url}?w=${1000}` : url;

    return (
        <span className={slideImageStyles}>
            <img className="swiper-lazy" data-src={imageUrl} src="" alt={title} />
        </span>
    );
};

export default GallerySlide;
