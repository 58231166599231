import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { IProjectColumnsModule, IProjectModuleImage } from '../../contentful/contentful-types.d';
import { ScreenSize } from '../../utils/decideScreenSize';
import ProjectImage from '../Image/Image';
import moduleColumnStyles from './styles/ModuleColumn.module.scss';

const styleColumns = 'ModuleColumns';
const styleColumnsColumn = `${styleColumns}-column`;
const styleColumnsSubColumn = `${styleColumns}-subcolumn`;

interface IProps extends IProjectColumnsModule {
    screenSize?: ScreenSize;
    availableWidth: number;
    availableHeight: number;
    loadImages: boolean;
}

const ProjectColumnModule: FunctionComponent<IProps> = (props) => {
    const { screenSize, column1, column2, availableWidth, availableHeight, loadImages } = props;
    return (
        <div className={moduleColumnStyles[styleColumns]}>
            {renderColumn(loadImages, availableWidth, availableHeight, column1, screenSize)}
            {renderColumn(loadImages, availableWidth, availableHeight, column2, screenSize)}
        </div>
    );
};

const renderColumn = (
    loadImage: boolean,
    availableWidth: number,
    availableHeight: number,
    modules?: IProjectModuleImage[],
    screenSize?: ScreenSize,
) => {
    const img1 = modules ? modules[0] : null;
    const img2 = modules ? modules[1] : null;
    const className = classnames(moduleColumnStyles[styleColumnsColumn]);
    const subColumnClass = classnames(moduleColumnStyles[styleColumnsSubColumn]);
    const maxHeight = availableWidth < 1024 ? `${availableHeight / 2}px` : `${availableHeight}px`;
    return (
        <div className={className}>
            {modules && modules.length <= 1 && img1 && (
                <ProjectImage
                    w={screenSize === ScreenSize.xs ? 1000 : undefined}
                    maxHeight={maxHeight}
                    imageSizeKey="ImageSize"
                    imageSizeStyles={moduleColumnStyles}
                    load={loadImage}
                    {...img1}
                />
            )}
            {modules && modules.length === 2 && img1 && img2 && (
                <>
                    <div className={subColumnClass}>
                        <ProjectImage
                            w={screenSize === ScreenSize.xs ? 1000 : undefined}
                            maxHeight={multiColumnMaxHeight(availableWidth, availableHeight, img1)}
                            imageSizeKey="ImageDoubleColumnSize"
                            imageSizeStyles={moduleColumnStyles}
                            load={loadImage}
                            {...img1}
                        />
                    </div>
                    <div className={subColumnClass}>
                        <ProjectImage
                            w={screenSize === ScreenSize.xs ? 1000 : undefined}
                            maxHeight={multiColumnMaxHeight(availableWidth, availableHeight, img2)}
                            imageSizeKey="ImageDoubleColumnSize"
                            imageSizeStyles={moduleColumnStyles}
                            load={loadImage}
                            {...img2}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const multiColumnMaxHeight = (availableWidth: number, availableHeight: number, image: IProjectModuleImage) => {
    const {
        size,
        image: rawImage,
    } = image;
    const width = rawImage?.file?.details?.image?.width || 0;
    const height = rawImage?.file?.details?.image?.height || 0;
    const landscape: boolean = width > height;
    const calcHeight = (availableHeight - 35) / 2;
    if (availableWidth < 1024 && landscape) {
        return size === 'sm' ? `${(calcHeight / 100) * 35}px` : `${(calcHeight / 100) * 55}px`;
    }
    if (availableWidth >= 1024 && landscape) {
        return size === 'sm' ? `${(availableHeight / 100) * 35}px` : `${(availableHeight / 100) * 55}px`;
    }
    return availableWidth < 1024 ? `${calcHeight}px` : `${availableHeight}px`;
};

export default ProjectColumnModule;
