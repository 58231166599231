export interface IUseLoopState {
    // --- CONFIG
    maxPages: number;
    pageHeight: number;

    // --- SCROLLING INFO
    // height of the cycle
    cycleHeight: number;
    // amount scrolled based on cycle height
    scrolled: number;
    // page number that reflects the scrolled position (based on top position)
    currentPage: number;
    // last snap (scroll position)
    lastSnap?: number;
    // height info per page
    heights: ElementHeight[];
    // where drag started
    dragStart: number | null;

    // --- ANIMATIONS FLAGS
    // user is doing a touch event
    isTouching: boolean;
    // user is performing a scroll event
    isScrolling: boolean;
    // snap timeout
    snapByTimeout: boolean;
    // page started to snapp
    isSnapping: boolean;
    // page has finished the snap
    isSnapped: boolean;
    // direction of the snap
    snapDirection?: 'up' | 'down';
}

export interface ElementHeight {
    index: number;
    height: number;
    offset: number;
}

/**
 * Create initial state
 * @param height
 * @param maxPages
 */
export default function initState(height: number, maxPages: number, initialPage = 0): IUseLoopState {
    return {
        maxPages,
        pageHeight: height,
        scrolled: 0,
        cycleHeight: 0,
        currentPage: initialPage,
        lastSnap: 0,
        dragStart: null,
        isTouching: false,
        isScrolling: false,
        snapByTimeout: false,
        isSnapping: false,
        isSnapped: true,
        snapDirection: undefined,
        heights: [],
    };
}
