import { KeyboardEvent, KeyboardEventHandler, TouchEvent, useState, WheelEvent } from 'react';
import { ITouchEvents, IWheelEvents } from '../../../hooks/types';

interface IUseSubSequence extends IWheelEvents, ITouchEvents {
    currentPage: number;
    onKeyDown: KeyboardEventHandler;
}

export default function useSubSequence(viewHeight: number, maxHeight: number, mobileSpeed: number): IUseSubSequence {
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [touchStart, setTouchStart] = useState<number>(0);

    const updateScrollPosition = (maxHeight: number) => (newPosition: number, touchStart?: number) => {
        let position = newPosition;
        position = newPosition < 0 ? 0 : position;
        position = newPosition > maxHeight ? maxHeight : position;
        setScrollPosition(position);
        if (touchStart) {
            setTouchStart(touchStart);
        }
    };

    const onWheel = ({ deltaY }: WheelEvent) => {
        const position = scrollPosition + deltaY;
        updateScrollPosition(maxHeight)(position);
    };

    const onTouchStart = (e: TouchEvent) => {
        const { clientY } = e.nativeEvent.touches[0];
        updateScrollPosition(maxHeight)(scrollPosition, clientY);
    };
    const onTouchMove = (e: TouchEvent) => {
        const { clientY } = e.nativeEvent.touches[0];
        const position = scrollPosition + (touchStart - clientY) * mobileSpeed;
        updateScrollPosition(maxHeight)(position, clientY);
    };
    const onTouchEnd = () => setTouchStart(0);

    const onKeyDown = (e: KeyboardEvent) => {
        switch (e.keyCode) {
            case 32:
            case 40:
                updateScrollPosition(maxHeight)(scrollPosition + viewHeight);
                return;
            case 38:
                updateScrollPosition(maxHeight)(scrollPosition - viewHeight);
                return;
        }
    };

    return {
        currentPage: scrollPosition / viewHeight || 0,
        onWheel,
        onTouchStart,
        onTouchMove,
        onTouchEnd,
        onKeyDown,
    };
}
