import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IProject, IProjectModuleImage } from '../../../contentful/contentful-types.d';
import useDimensions from '../../../hooks/useDimensions';
import { ScreenSize } from '../../../utils/decideScreenSize';

interface IUseProjectsOverview {
    // view size
    viewWidth: number;
    viewHeight: number;
    // cards
    cards: ICard[] | null;
    cardDimensions: ICardDimensions;
    // transition
    projectTransitioning: boolean;
    projectTransition: IProject | null;
    setProjectTransition: (p: IProject | null) => void;
}

export interface ICardDimensions {
    portrait: number;
    portraitExpansion: number;
    landscape: number;
    landscapeExpansion: number;
}

export interface ICard {
    project: IProject;
    image: IProjectModuleImage;
    selectedProject?: IProject | null;
    selectProject?: (p: IProject) => void;
    isLandscape: boolean;
    width?: number;
    widthExpansion?: number;
}

export default function useProjectsOverview(getCards: (maxItems: number) => ICard[]): IUseProjectsOverview {
    const history = useHistory();
    // screen size
    const { screensize, viewHeight, viewWidth } = useDimensions();
    // max images per project
    const [maxItems, setMaxItems] = useState<number>(1);
    // cards state
    const [cards, setCards] = useState<ICard[] | null>(null);
    const [cardDimensions, setCardDimensions] = useState<ICardDimensions>(defaultDimensions);
    const [projectTransitioning, setProjectTransitioning] = useState<boolean>(false);
    const [projectTransition, setProjectTransition] = useState<IProject | null>(null);

    // update cards to show
    useEffect(() => setCards(getCards(maxItems)), [getCards, maxItems]);

    // handle route
    const fadeOutTimeout = useRef<NodeJS.Timeout | null>(null);
    const redirectTimeout = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (!projectTransition) {
            return;
        }

        // fade out timeouts
        if (fadeOutTimeout.current) {
            clearTimeout(fadeOutTimeout.current);
        }
        fadeOutTimeout.current = setTimeout(() => {
            setProjectTransitioning(true);
        }, 1000);

        // redirec timeouts
        if (redirectTimeout.current) {
            clearTimeout(redirectTimeout.current);
        }
        redirectTimeout.current = setTimeout(() => {
            if (projectTransition) {
                history.push(`/series/${projectTransition.path}`);
            }
        }, 1500);

        return () => {
            if (fadeOutTimeout.current) {
                clearTimeout(fadeOutTimeout.current);
            }
            if (redirectTimeout.current) {
                clearTimeout(redirectTimeout.current);
            }
        };
    }, [projectTransition, redirectTimeout, fadeOutTimeout, history]);

    // decide settings by breakpoint
    useEffect(() => {
        switch (screensize) {
            case ScreenSize.xs:
                // 17 cols -> 40
                setCardDimensions(calculateDimensions(17, 40));
                setMaxItems(1);
                break;
            // case ScreenSize.sm:
            //     // 11 cols -> 23
            //     setCardDimensions(calculateDimensions(11, 23));
            //     setMaxItems(1);
            //     break;
            case ScreenSize.md:
                // 11 cols -> 25
                setCardDimensions(calculateDimensions(11, 25));
                setMaxItems(1);
                break;
            case ScreenSize.lg:
                // 9 cols -> 20
                setCardDimensions(calculateDimensions(9, 20));
                setMaxItems(1);
                break;
            case ScreenSize.xl:
                // 5 cols -> 14
                setCardDimensions(calculateDimensions(5, 14));
                setMaxItems(3);
                break;
        }
    }, [screensize]);

    return {
        cards,
        viewWidth,
        viewHeight,
        cardDimensions: cardDimensions,
        projectTransitioning,
        projectTransition,
        setProjectTransition,
    };
}

const calculateDimensions = (portraitCols: number, portraitHoverCols: number): ICardDimensions => {
    // ratio
    const ratio = (5 * 5) / 4 / 4;
    // portrait
    const portrait = (portraitCols / 80) * 100;
    const portraitHover = (portraitHoverCols / 80) * 100;
    const portraitExpansion = portraitHover - portrait;
    // landscape
    const landscape = portrait * ratio;
    const landscapeExpansion = portraitExpansion * ratio;
    return {
        portrait,
        portraitExpansion,
        landscape,
        landscapeExpansion,
    };
};

const defaultDimensions = calculateDimensions(20, 40);
