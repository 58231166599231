import { IUseLoopState } from './initState';
import recalculateScroll from './recalculateScroll';

/**
 * End of scroll
 * @param state
 */
function handleEndScroll(state: IUseLoopState): IUseLoopState {
    return recalculateScroll(0, {
        ...state,
        lastSnap: state.scrolled,
        dragStart: null,
        isSnapped: true,
        isScrolling: false,
        isTouching: false,
        isSnapping: false,
        snapDirection: undefined,
    });
}

/**
 * Snap phase start
 * @param state
 */
function handleSnap(state: IUseLoopState, snap: number): IUseLoopState {
    const { scrolled } = state;
    return recalculateScroll(0, {
        ...state,
        dragStart: null,
        scrolled: snap,
        snapByTimeout: false,
        isScrolling: false,
        isTouching: false,
        isSnapping: true,
        snapDirection: snap > scrolled ? 'up' : 'down',
    });
}

function shouldSnap(state: IUseLoopState): IUseLoopState {
    const { scrolled, pageHeight, lastSnap = 0 } = state;

    // do nothing if no scroll change
    if (lastSnap === scrolled) {
        return state;
    }

    // calculate offset
    const last = lastSnap / pageHeight;
    const next = scrolled / pageHeight;

    // scrolling up (including first to last page)
    if (next < last || (next > last && next - last > 1)) {
        return handleSnap(state, Math.floor(next) * pageHeight);
    }

    // scrolling down
    return handleSnap(state, Math.floor(next) * pageHeight + pageHeight);
}

export { handleEndScroll, handleSnap, shouldSnap };
