import React, { FunctionComponent } from 'react';

import Cards from '../../components/Cards/Cards';
import Spinner from '../../components/Spinner/Spinner';

import useGlobalClass from '../../hooks/useGlobalClass';
import { useContentfulData } from '../../contentful';
import { IProject } from '../../contentful/contentful-types.d';

const HomePage: FunctionComponent = () => {
    useGlobalClass('page-home');
    useGlobalClass('with-navigation');
    const { items, ...rest } = useContentfulData<IProject>({ contentType: 'project' });
    return (
        <Spinner {...rest}>
            <Cards items={items} />
        </Spinner>
    );
};

export default HomePage;
