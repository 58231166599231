import React, { FunctionComponent } from 'react';

import { IProject } from '../../contentful/contentful-types.d';
import { DecideHeightByItem } from '../LoopScroll/hooks/useLoop';
import decideScreenSize, { ScreenSize } from '../../utils/decideScreenSize';

import LoopScrollView from '../LoopScroll';
import ProjectHeader from './ProjectHeader';
import ProjectModule from './ProjectModule';

import styles from './styles/Project.module.scss';
import useBackgroundIndex from '../../hooks/useBackgroundIndex';
import classNames from 'classnames';

interface IProjectComponent extends IProject {
    initialPage: number;
}

const Project: FunctionComponent<IProjectComponent> = (props) => {
    const { initialPage, modules, path } = props;
    const decideHeightByItem: DecideHeightByItem = (idx: number, scrollViewWidth: number, scrollViewHeight: number) => {
        const { contentType } = modules[idx];
        const screenSize: ScreenSize = decideScreenSize(scrollViewWidth);
        const isBigScreen = [ScreenSize.xl, ScreenSize.lg].find((x) => screenSize === x) !== undefined;
        if (contentType === 'columnsModule' && !isBigScreen) {
            return scrollViewHeight * 2;
        }
        return scrollViewHeight;
    };

    const bgIndex = useBackgroundIndex();
    const itemClass = classNames({
        [styles['Project-bg1']]: bgIndex === 1,
        [styles['Project-bg2']]: bgIndex === 2,
        [styles['Project-bg3']]: bgIndex === 3,
        [styles['Project-bg4']]: bgIndex === 4,
    });

    // render helpers
    const renderHeader = (idx?: number) => {
        return <ProjectHeader currentPage={idx} {...props} />;
    };

    const renderItem = (idx: number, scrollViewWidth: number, availableHeight: number, loadImages: boolean) => {
        return (
            <ProjectModule
                projectPath={path}
                moduleIndex={idx}
                item={modules[idx]}
                availableWidth={scrollViewWidth}
                availableHeight={availableHeight}
                loadImages={loadImages}
            />
        );
    };

    const decideItemClass = (idx: number) => {
        return modules[idx].subSequence ? itemClass : undefined;
    };

    return (
        <div className={styles['Project']}>
            <LoopScrollView
                decideItemClass={decideItemClass}
                initialPage={initialPage}
                maxPages={modules.length}
                decideHeightByItem={decideHeightByItem}
                renderHeader={renderHeader}
                renderItem={renderItem}
            />
        </div>
    );
};

export default Project;
