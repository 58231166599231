import React, { FunctionComponent } from 'react';
import { IContact } from '../../contentful/contentful-types.d';
import { AboutBlock } from '../AboutBlocks';

const CreditsBlock: FunctionComponent<IContact> = (props) => {
    const { credits } = props;
    return <AboutBlock header="credits">{credits}</AboutBlock>;
};

export default CreditsBlock;
