import React, { FunctionComponent } from 'react';

import { HomeLink } from '../../routes/Home';
import { ArchiveLink } from '../../routes/Archive';
import { AboutLink } from '../../routes/About';

import styles from './Navigation.module.scss';
import { useRouteMatch } from 'react-router-dom';

const Navigation: FunctionComponent = () => {
    const isArchive = useRouteMatch('/archive');
    return (
        <nav className={styles['Navigation']}>
            <HomeLink>
                <>
                    <span className={styles['Navigation-only-xs']}>Fuembuena</span>
                    <span className={styles['Navigation-sm']}>Jorge Fuembuena</span>
                </>
            </HomeLink>
            <ArchiveLink className={styles['Navigation--center']}>
                {!isArchive ? <span>Archive</span> : <span />}
            </ArchiveLink>
            <AboutLink className={styles['Navigation--right']}>
                <span>About</span>
            </AboutLink>
        </nav>
    );
};

export default Navigation;
