import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ClientProvider } from '../../contentful';
import Navigation from '../Navigation';
import Routes from '../../routes/Routes';
import '../../assets/styles/main.scss';

const App: FunctionComponent = () => {
    return (
        <ClientProvider>
            <BrowserRouter>
                <Navigation />
                <Routes />
            </BrowserRouter>
        </ClientProvider>
    );
};

export default App;
