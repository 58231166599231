import React, { FunctionComponent, MouseEvent } from 'react';
import classnames from 'classnames';

import { IProjectModuleImage } from '../../contentful/contentful-types.d';

import styles from './Search.module.scss';

interface props extends IProjectModuleImage {
    onClick: (e: MouseEvent) => void;
}

const ImageResult: FunctionComponent<props> = (props) => {
    const { onClick, image, title } = props;

    if (!image?.file) {
        return null;
    }

    const { file, description } = image;
    const {
        url,
        details: {
            image: { height, width },
        },
    } = file;

    const landscape: boolean = width > height;
    const className = classnames([styles['ImageResult']], {
        [styles[`ImageResult--landscape`]]: landscape,
        [styles[`ImageResult--portrait`]]: !landscape,
    });

    const setHover = (hasHover: boolean) => {
        const className = 'search-image-hovered';
        const classList = document.getElementById('App')?.classList;
        if (hasHover) {
            classList?.add(className);
        } else {
            classList?.remove(className);
        }
    };

    return (
        <span
            className={className}
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <img src={`${url}?w=400`} alt={description || title} />
        </span>
    );
};

export default ImageResult;
