import { useEffect, useRef, useState } from 'react';
import { HookQueryProps, useContentful } from 'react-contentful';
import { ContentfulError, UseContentfulData } from '../contentful-types.d';

import cleanData from '../utils/cleanData';

interface Query {
    skip: number;
}

interface Data<T> extends UseContentfulData<T> {
    loadMore?: () => void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    fetchWithQuery: (query: object) => void;
}

function useContentfulData<T>(options: HookQueryProps): Data<T> {
    const { query: initialQuery, ...rest } = options;

    // basic state
    // eslint-disable-next-line @typescript-eslint/ban-types
    const [query, setQuery] = useState<object | undefined>(initialQuery);
    const [skip, setSkip] = useState<number>((options?.query as Query)?.skip || 0);
    const paginating = useRef<boolean>(false);
    const [forceLoadMore, setForceLoadMore] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/ban-types
    // const [stateData, setStateData] = useState<object | undefined>();
    const [items, setItems] = useState<T[]>([]);

    // fetch
    const { data, error, loading, fetched } = useContentful({
        ...rest,
        ...{ query: { ...{ skip }, ...query } },
    });

    // prepare data info
    const loadedItems = items.length;
    const hasMore = items.length < (data as { total: number })?.total;

    // load more event
    const loadMore = () => setForceLoadMore(true);

    // update query
    // eslint-disable-next-line @typescript-eslint/ban-types
    const fetchWithQuery = (query: object) => {
        paginating.current = false;
        setQuery(query);
        setItems([]);
        setSkip(0);
    };

    // handle load more
    useEffect(() => {
        if (forceLoadMore) {
            paginating.current = true;
            setForceLoadMore(false);
            setSkip(loadedItems);
        }
    }, [forceLoadMore, loadedItems]);

    // merge data
    useEffect(() => {
        if (!fetched || loading) {
            return;
        }
        const castedData = data as { items: T[]; skip: number };
        const { items } = castedData;
        const newItems = cleanData(items);
        setItems((items) => (paginating.current ? [...items, ...newItems] : newItems));
    }, [fetched, loading, data, paginating]);

    return {
        items,
        error: error as ContentfulError,
        loading,
        fetched,
        loadMore: hasMore ? loadMore : undefined,
        fetchWithQuery,
    };
}

export default useContentfulData;
