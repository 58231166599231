import { random, shuffle } from 'lodash';
import getRandomItems from '../../../utils/getRandomItems';

export interface ICoordinates {
    x: number;
    y: number;
}

export default function initSpreadedItems(width: number, height: number, maxItems: number): ICoordinates[] {
    const { ceil, sqrt } = Math;
    const divisions = ceil(sqrt(maxItems));
    const block = 100 / divisions;
    const variability = 3;
    const blockVariable = block / variability;

    // prepare positions
    let coordinates: ICoordinates[] = [];
    for (let i = 0; i < divisions; i++) {
        for (let j = 0; j < divisions; j++) {
            const rowStart = i * block;
            const colStart = j * block;
            coordinates.push({
                x: rowStart + block / 2 + random(-blockVariable, blockVariable),
                y: colStart + block / 2 + random(-blockVariable, blockVariable),
            });
        }
    }

    // choose only needed positions
    coordinates = getRandomItems<ICoordinates>(coordinates, maxItems);

    const spacer = 1.2;
    const widthSpaced = width * spacer;
    const heightSpaced = height * spacer;

    // convert to pixels
    coordinates = coordinates.map((pos) => ({
        x: widthSpaced * (pos.x / 100) - widthSpaced / 2,
        y: heightSpaced * (pos.y / 100) - heightSpaced / 2,
    }));

    // shuffle
    return shuffle(coordinates);
}
