import { debounce } from 'lodash';
import React, { FunctionComponent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Search.module.scss';

interface props {
    value: string;
    setValue: (value: string) => void;
}

const InfoPage: FunctionComponent<props> = (props) => {
    const { value, setValue } = props;
    const hasData = value != null && value.length > 0;

    // state
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>(value);
    const [focus, setHasFocus] = useState<boolean>(false);
    const setValueDebounced = useCallback(
        debounce(() => setValue(inputRef.current?.value || ''), 600, { leading: false, trailing: true }),
        [],
    );

    // match value changes outside the input
    useEffect(() => setInputValue(value), [value]);

    const onClick = (e: MouseEvent) => {
        e.preventDefault();
        setHasFocus(true);
        setTimeout(() => inputRef.current?.focus(), 0);
    };

    const onChange = () => {
        setInputValue(inputRef.current?.value || '');
        setValueDebounced();
    };

    return (
        <>
            {!hasData && !focus && (
                <div onClick={onClick} className={styles['SearchInput']}>
                    Search
                </div>
            )}

            <input
                ref={inputRef}
                onClick={onClick}
                value={inputValue}
                className={styles['SearchInput']}
                type="text"
                onChange={onChange}
                onBlur={() => setHasFocus(false)}
            />
        </>
    );
};

export default InfoPage;
