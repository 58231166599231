import { IUseLoopState } from './initState';
import recalculateScroll from './recalculateScroll';

/**
 * On wheel handling
 * @param offset
 * @param state
 */
export default function addWheelOffset(offset: number, state: IUseLoopState): IUseLoopState {
    return recalculateScroll(offset, {
        ...state,
        dragStart: null,
        isTouching: false,
        isScrolling: true,
        isSnapped: false,
        isSnapping: false,
        snapDirection: undefined,
    });
}
