import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { HorizontalAlignment, IProjectModuleImage, VerticalAlignment } from '../../contentful/contentful-types.d';
import styles from './Image.module.scss';

const baseClass = 'ProjectImage';

interface IProps extends IProjectModuleImage {
    load?: boolean;
    w?: number;
    maxHeight?: string;
    imageSizeKey?: string;
    imageSizeStyles?: { [x: string]: string };
}

const ProjectImage: FunctionComponent<IProps> = (props) => {
    const {
        size,
        image,
        xsHorizontalAlignment,
        smHorizontalAlignment,
        mdHorizontalAlignment,
        lgHorizontalAlignment,
        xlHorizontalAlignment,
        xsVerticalAlignment,
        smVerticalAlignment,
        mdVerticalAlignment,
        lgVerticalAlignment,
        xlVerticalAlignment,
        w,
        load = true,
        maxHeight = '100%',
        imageSizeKey = 'ImageSize',
        imageSizeStyles = styles,
    } = props;

    // lazy loading images state
    const [showImage, setShowImage] = useState<boolean>(load);
    useEffect(() => {
        if (!showImage && load) {
            setShowImage(true);
        }
    }, [load, showImage]);

    if (!image?.file) {
        return null;
    }

    const { file, title, description } = image;
    const {
        url,
        details: {
            image: { height, width },
        },
    } = file;
    const imageUrl = w ? `${url}?w=${w}` : url;

    // styles
    const landscape: boolean = width > height;
    const imageStyles = decideImageStyles(imageSizeKey, imageSizeStyles, size, landscape);
    const className = classnames([styles[baseClass]], {
        [styles[`${baseClass}-landscape`]]: landscape,
        [styles[`${baseClass}-portrait`]]: !landscape,
        ...decideAligmentStyles('xs', xsVerticalAlignment, xsHorizontalAlignment),
        ...decideAligmentStyles('sm', smVerticalAlignment, smHorizontalAlignment),
        ...decideAligmentStyles('md', mdVerticalAlignment, mdHorizontalAlignment),
        ...decideAligmentStyles('lg', lgVerticalAlignment, lgHorizontalAlignment),
        ...decideAligmentStyles('xl', xlVerticalAlignment, xlHorizontalAlignment),
        ...imageStyles,
    });
    const wrapperStyles = {
        maxHeight,
    };

    return (
        <div className={className} style={wrapperStyles}>
            <img src={showImage ? imageUrl : ''} alt={description || title} style={wrapperStyles} />
        </div>
    );
};

const decideAligmentStyles = (
    size: string,
    vertical: string = VerticalAlignment.middle,
    horizontal: string = HorizontalAlignment.center,
) => {
    return {
        [styles[`${baseClass}-${size}-${horizontal}`]]: true,
        [styles[`${baseClass}-${size}-${vertical}`]]: true,
    };
};

const decideImageStyles = (key: string, stylesSrc: { [x: string]: string }, size: string, landscape: boolean) => {
    return {
        [stylesSrc[`${key}-${size}-landscape`]]: landscape,
        [stylesSrc[`${key}-${size}-portrait`]]: !landscape,
    };
};

export default ProjectImage;
