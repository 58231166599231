import React, { FunctionComponent } from 'react';
import Link, { ILinkProps } from '../../components/Link/Link';

interface Props extends ILinkProps {
    page?: number;
}

const ProjectLink: FunctionComponent<Props> = (props) => {
    const { path, page } = props;
    return <Link {...props} path={`/series/${path}`} state={{ page }} />;
};

export default ProjectLink;
