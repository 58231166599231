import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { IProject } from '../../contentful/contentful-types.d';

import Project from '../../components/Project';
import { useContentfulData } from '../../contentful';
import Spinner from '../../components/Spinner/Spinner';

interface IProjectPageProps {
    path: string;
}

interface IProjectPageState {
    page?: number;
}

const ProjectPage: FunctionComponent = () => {
    // read path and state
    const { path } = useParams<IProjectPageProps>();
    const { state } = useLocation<IProjectPageState>();
    const { page = 0 } = state || { page: 0 };

    // fetch data
    const { items, ...rest } = useContentfulData<IProject>({
        contentType: 'project',
        query: {
            'fields.path': path,
        },
    });

    const project = items.length > 0 ? items[0] : null;

    return <Spinner {...rest}>{project && <Project {...project} initialPage={page} />}</Spinner>;
};

export default ProjectPage;
