import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { animated } from 'react-spring';
import useParallax, { IParallaxCardDimensions } from './hook/useParallax';
import styles from './Parallax.module.scss';

interface ParallaxProps {
    // styles
    className: string;
    // dimensions
    viewWidth: number;
    viewHeight: number;
    // card info
    cardClassName: string;
    cardsCount: number;
    getCardDimensions: (idx: number) => IParallaxCardDimensions;
    renderCard: (idx: number) => ReactNode;
}

const Parallax: FunctionComponent<ParallaxProps> = (props) => {
    const { className, viewWidth, viewHeight, cardClassName, cardsCount, getCardDimensions, renderCard } = props;
    const { items, wrapperProps, getStylesForItem } = useParallax({
        viewWidth,
        viewHeight,
        maxItems: cardsCount,
    });
    const wrapperClass = classNames(className, styles['Parallax']);
    const cardClass = classNames(cardClassName, styles['ParallaxCard']);
    return (
        <div className={wrapperClass} {...wrapperProps}>
            {items.map((item, idx) => {
                const dimensions = getCardDimensions(idx);
                return (
                    <animated.div key={idx} className={cardClass} style={getStylesForItem(item, dimensions)}>
                        {renderCard(idx)}
                    </animated.div>
                );
            })}
        </div>
    );
};

export default Parallax;
