import { IUseLoopState } from './initState';
import recalculateScroll from './recalculateScroll';

/**
 * Handling dragging
 * @param dragStart
 * @param state
 */
function touchStart(dragStart: number, state: IUseLoopState): IUseLoopState | null {
    const { isScrolling, isTouching } = state;
    if (isScrolling || isTouching) {
        return null;
    }
    return recalculateScroll(0, {
        ...state,
        dragStart,
        isTouching: true,
        isScrolling: false,
        isSnapped: false,
        isSnapping: false,
        snapDirection: undefined,
    });
}

function touchMove(currentDrag: number, state: IUseLoopState): IUseLoopState | null {
    const { isTouching, dragStart } = state;
    if (!isTouching || !dragStart) {
        return state;
    }
    const offset = dragStart - currentDrag;
    return recalculateScroll(offset, {
        ...state,
        dragStart: currentDrag,
        isTouching: true,
        isScrolling: false,
        isSnapped: false,
        isSnapping: false,
        snapDirection: undefined,
    });
}

function touchEnd(state: IUseLoopState): IUseLoopState {
    return recalculateScroll(0, {
        ...state,
        dragStart: null,
        isTouching: false,
    });
}

export { touchStart, touchMove, touchEnd };
