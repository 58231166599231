import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, ReactNode, useState } from 'react';
import styles from './Accordion.module.scss';

interface props {
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    horizontal?: boolean;
    reverted?: boolean;
    split1: () => ReactNode;
    split2: () => ReactNode;
}

const SplitView: FunctionComponent<props> = (props) => {
    const { className, disabled = false, horizontal = false, reverted = false, split1, split2, style } = props;

    const [index, setIndex] = useState<number>(reverted ? 1 : 0);

    const splitViewClass = classNames(styles['Accordion'], className, {
        [styles['Accordion--disabled']]: disabled,
        [styles['Accordion--horizontal']]: horizontal,
    });
    const primaryClass = classNames(styles['AccordionSection'], {
        [styles['AccordionSection--active']]: index === 0 || disabled,
    });
    const secondaryClass = classNames(styles['AccordionSection'], {
        [styles['AccordionSection--active']]: index === 1 || disabled,
    });

    return (
        <div className={splitViewClass} style={style}>
            <section className={primaryClass} onClick={() => setIndex(0)}>
                {split1()}
            </section>
            <section className={secondaryClass} onClick={() => setIndex(1)}>
                {split2()}
            </section>
        </div>
    );
};

export default SplitView;
