import classNames from 'classnames';
import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Accordion from '../../components/Accordion';
import Gallery from '../../components/Gallery/Gallery';
import ImageSearch, { Input } from '../../components/ImageSearch';
import useDimensions from '../../hooks/useDimensions';
import useGlobalClass from '../../hooks/useGlobalClass';
import { ScreenSize } from '../../utils/decideScreenSize';
import styles from './Archive.module.scss';
import useArchiveSearch from './hooks/useArchiveSearch';
import useArchiveUX from './hooks/useArchiveUX';

const decideHeight = (screensize: ScreenSize, viewHeight: number) => {
    switch (screensize) {
        case ScreenSize.xs:
            // case ScreenSize.sm:
            return viewHeight - 35;
        case ScreenSize.md:
        case ScreenSize.lg:
            return viewHeight - 72;
        case ScreenSize.xl:
            return viewHeight - 60;
    }
};

const ArchivePage: FunctionComponent = () => {
    useGlobalClass('with-navigation');
    const { screensize, viewHeight } = useDimensions();
    const [height, setHeight] = useState<number>(decideHeight(screensize, viewHeight));
    const disabled = screensize !== ScreenSize.xs && screensize !== ScreenSize.md;

    useLayoutEffect(() => setHeight(decideHeight(screensize, viewHeight)), [screensize, viewHeight]);

    // STATE
    const {
        search,
        personalItems,
        personalMore,
        commissionedItems,
        commissionedMore,
        filteredItems,
        filteredMore,
        tags,
        setTags,
    } = useArchiveSearch();
    const { showMain, showResults } = useArchiveUX({ tags });
    const [galleryType, setGalleryType] = useState<string | null>(null);
    const [initialPage, setInitialPage] = useState<number | null>(null);

    // EVENTS
    const openGallery = (type: string, initial: number) => {
        setGalleryType(type);
        setInitialPage(initial);
    };
    const closeGallery = () => {
        setGalleryType(null);
        setInitialPage(null);
    };
    const onReachEnd = () => {
        if (galleryType && galleryType === 'personal' && personalMore) {
            personalMore();
        }
        if (galleryType && galleryType === 'commissioned' && commissionedMore) {
            commissionedMore();
        }
        if (galleryType && galleryType === 'filtered' && filteredMore) {
            filteredMore();
        }
    };

    // decide gallery items
    let galleryItems = null;
    if (galleryType && galleryType === 'personal') {
        galleryItems = personalItems;
    }
    if (galleryType && galleryType === 'commissioned') {
        galleryItems = commissionedItems;
    }
    if (galleryType && galleryType === 'filtered') {
        galleryItems = filteredItems;
    }

    // STYLES
    const galleryClass = classNames(styles['ArchiveGallery'], {
        [styles['ArchiveGallery--open']]: initialPage !== null,
    });

    return (
        <div className={styles['Archive']} style={{ height }}>
            <Input value={tags} setValue={setTags} />
            <CSSTransition appear in={showMain} timeout={500} classNames="accordion">
                <Accordion
                    className={styles['ArchiveContent']}
                    disabled={disabled}
                    horizontal={disabled}
                    split1={() => (
                        <ImageSearch
                            header="personal"
                            type="personal"
                            search={search}
                            items={personalItems}
                            loadMore={personalMore}
                            openGallery={openGallery}
                        />
                    )}
                    split2={() => (
                        <ImageSearch
                            header="commissioned"
                            type="commissioned"
                            search={search}
                            items={commissionedItems}
                            loadMore={commissionedMore}
                            openGallery={openGallery}
                        />
                    )}
                />
            </CSSTransition>
            <CSSTransition in={showResults} timeout={500} classNames="search-results">
                <ImageSearch
                    type="filtered"
                    items={filteredItems}
                    search={search}
                    loadMore={filteredMore}
                    openGallery={openGallery}
                    className="search-results"
                    fullscreen
                />
            </CSSTransition>
            <Gallery
                setTags={setTags}
                className={galleryClass}
                items={galleryItems}
                initialPage={initialPage}
                closeGallery={closeGallery}
                onReachEnd={onReachEnd}
            />
        </div>
    );
};

export default ArchivePage;
