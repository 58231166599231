const cleanData = (data: any): any => {
    // flatter array
    if (Array.isArray(data)) {
        return data.map((i) => cleanData(i));
    }

    // falttern objects
    if (typeof data === 'object' && data !== null) {
        // no needed level
        if (data.fields) {
            const contentType = data.sys?.contentType?.sys?.id;
            const flat = cleanData(data.fields);
            return contentType ? { ...flat, ...{ contentType } } : flat;
        }

        // data level
        const newData: any = {};
        for (const key of Object.keys(data)) {
            newData[key] = cleanData(data[key]);
        }

        return newData;
    }

    // normal types
    return data;
};

export default cleanData;
