import { IUseLoopState, ElementHeight } from './initState';
import recalculateScroll from './recalculateScroll';

/**
 * Handle height change
 * @param height
 * @param state
 */
export default function handleHeightChange(
    height: number,
    state: IUseLoopState,
    decideHeight: (index: number) => number,
): IUseLoopState {
    const { maxPages, currentPage } = state;
    let offset = 0;
    const heights: ElementHeight[] = [];
    let scrolledSnap = 0;
    for (let index = 0; index < maxPages; index++) {
        const elementHeight = decideHeight(index);
        heights.push({
            index,
            height: elementHeight,
            offset,
        });
        if (currentPage === index) {
            scrolledSnap = offset;
        }
        offset = offset + elementHeight;
    }
    return recalculateScroll(0, {
        ...state,
        ...{ pageHeight: height, cycleHeight: offset, scrolled: scrolledSnap, lastSnap: scrolledSnap, heights },
    });
}
