import React, { FunctionComponent, ReactNode, useRef, RefObject, useEffect } from 'react';
import { toInteger } from 'lodash';

import useLoop, { DecideHeightByItem } from './hooks/useLoop';
import useDimensions from '../../hooks/useDimensions';
import styles from './LoopScroll.module.scss';
import classNames from 'classnames';

interface iLoopScrollProps {
    className?: string;
    initialPage: number;
    maxPages: number;
    renderHeader: (numberindex?: number) => ReactNode;
    renderItem: (index: number, width: number, height: number, loadImages: boolean) => ReactNode;
    decideHeightByItem: DecideHeightByItem;
    decideItemClass: (idx: number) => string | undefined;
}

const LoopScroll: FunctionComponent<iLoopScrollProps> = (props) => {
    const { className, maxPages, initialPage, renderHeader, renderItem, decideHeightByItem, decideItemClass } = props;
    // dom references
    const headerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const loopRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    // height hook
    const { viewWidth, viewHeight } = useDimensions(headerRef);
    // loop hook
    const { currentPage, wrapperProps, getStylesByIndex, next, prev } = useLoop({
        maxPages,
        initialPage,
        viewWidth,
        viewHeight,
        decideHeightByItem,
    });

    const items = [];
    for (let index = 0; index < maxPages; index++) {
        const inlineStyles = getStylesByIndex(index);
        const loadImage = inlineStyles?.opacity === 1;
        items.push(
            <div key={index} className={styles['LoopScroll-el']} style={inlineStyles}>
                <div className={classNames(styles['LoopScroll-el-wrapper'], decideItemClass(index))}>
                    {renderItem(index, viewWidth, toInteger(inlineStyles?.height), loadImage)}
                </div>
            </div>,
        );
    }

    useEffect(() => loopRef.current?.focus(), []);

    const loopClass = classNames(styles['LoopScroll'], className);

    return (
        <>
            <div ref={headerRef}> {renderHeader(viewHeight > 0 ? currentPage : undefined)}</div>
            <div ref={loopRef} className={loopClass} style={{ height: viewHeight }} {...wrapperProps}>
                <div onClick={() => next()} className={styles.Next} />
                {viewHeight > 0 && items}
                <div onClick={() => prev()} className={styles.Prev} />
            </div>
        </>
    );
};

export default LoopScroll;
