export interface ContentfulError {
    message: string;
}

export interface ContentfulSpinner {
    error?: ContentfulError;
    loading: boolean;
    fetched: boolean;
}

export interface UseContentfulData<T> extends ContentfulSpinner {
    items: T[];
}

interface IContentType {
    contentType: string;
}

interface IFileDetailsImage {
    width: number;
    height: number;
}

interface IFileDetails {
    size: number;
    image: IFileDetailsImage;
}

interface IFile extends IContentType {
    fileName: string;
    url: string;
    details: IFileDetails;
}

export interface IImage {
    title: string;
    description: string;
    file: IFile;
}

export enum HorizontalAlignment {
    left = 'left',
    right = 'right',
    center = 'center',
}

export enum VerticalAlignment {
    top = 'top',
    botom = 'botom',
    middle = 'middle',
}

export interface IProject extends IContentType {
    title: string;
    path: string;
    modules: (IProjectModule | IProjectColumnsModule)[];
}

export interface ISubSequence extends IContentType {
    title: string;
    path: string;
    modules: ISubSequenceModule[];
    mobileSpeed: number;
}

export interface IProjectModule extends IContentType {
    title: string;
    image: IProjectModuleImage;
    subSequence?: string;
    reversed?: boolean;
}

export interface IProjectColumnsModule extends IContentType {
    title: string;
    column1?: IProjectModuleImage[];
    column2?: IProjectModuleImage[];
    subSequence?: string;
    reversed?: boolean;
}

export interface ISubSequenceModule extends IContentType {
    title: string;
    image: IProjectModuleImage;
    in?: number;
    fadeIn?: number;
    out?: number;
    fadeOut?: number;
    backToProject?: string;
    backToProjectPage?: number;
}

export interface IProjectModuleImage extends IContentType {
    title: string;
    size: 'xl' | 'lg' | 'md' | 'sm';
    image?: IImage;
    xsHorizontalAlignment?: HorizontalAlignment;
    smHorizontalAlignment?: HorizontalAlignment;
    mdHorizontalAlignment?: HorizontalAlignment;
    lgHorizontalAlignment?: HorizontalAlignment;
    xlHorizontalAlignment?: HorizontalAlignment;
    xsVerticalAlignment?: VerticalAlignment;
    smVerticalAlignment?: VerticalAlignment;
    mdVerticalAlignment?: VerticalAlignment;
    lgVerticalAlignment?: VerticalAlignment;
    xlVerticalAlignment?: VerticalAlignment;
    homepage: boolean;
    archiveType: 'personal' | 'commissioned';
    archiveTags: string;
}

export interface IAboutBlock extends IContentType {
    label: string;
    content: string;
    contentMarkdown: {
        data: any;
    };
    tagSearch: string;
    priority?: number;
}

export interface IContact extends IContentType {
    shortInfo: string;
    email: string;
    phoneSpain?: string;
    phoneFrance?: string;
    instagram?: string;
    facebook?: string;
    vimeo?: string;
    credits: string;
    emailImage: IImage;
    socialMediaImage: IImage;
    contactImage: IImage;
    archiveType?: 'personal' | 'commissioned';
    archiveTags?: string;
}
