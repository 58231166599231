import { useEffect } from 'react';

export default function useGlobalClass(className: string): void {
    useEffect(() => {
        document.getElementById('App')?.classList.add(className);
        return () => {
            document.getElementById('App')?.classList.remove(className);
        };
    });
}
