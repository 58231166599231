import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import AboutBlocks from '../../components/AboutBlocks';
import Accordion from '../../components/Accordion';
import ContactAccordion from '../../components/ContactAccordion';
import useDimensions from '../../hooks/useDimensions';
import useGlobalClass from '../../hooks/useGlobalClass';
import { ScreenSize } from '../../utils/decideScreenSize';
import styles from './About.module.scss';

const decideHeight = (screensize: ScreenSize, viewHeight: number) => {
    switch (screensize) {
        case ScreenSize.xs:
            // case ScreenSize.sm:
            return viewHeight - 35;
        case ScreenSize.md:
        case ScreenSize.lg:
            return viewHeight - 72;
        case ScreenSize.xl:
            return viewHeight - 60;
    }
};

const AboutLayout: FunctionComponent = () => {
    useGlobalClass('with-navigation');
    const { screensize, viewHeight } = useDimensions();
    const [height, setHeight] = useState<number>(decideHeight(screensize, viewHeight));
    const disabled = screensize !== ScreenSize.xs;
    useLayoutEffect(() => {
        setHeight(decideHeight(screensize, viewHeight));
    }, [screensize, viewHeight]);
    return (
        <Accordion
            className={styles['About']}
            style={{ height }}
            disabled={disabled}
            horizontal={screensize !== ScreenSize.xs && screensize !== ScreenSize.md}
            reverted
            split1={() => <ContactAccordion screensize={screensize} />}
            split2={() => <AboutBlocks />}
        />
    );
};

export default AboutLayout;
