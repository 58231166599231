import React, { CSSProperties, FunctionComponent, MouseEventHandler, TouchEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ILinkProps {
    className?: string;
    style?: CSSProperties;
    path?: string;
    children?: JSX.Element;
    search?: string;
    state?: { [key: string]: string | number | boolean | undefined };
    onClick?: MouseEventHandler;
    onMouseMove?: MouseEventHandler;
    onMouseOver?: MouseEventHandler;
    onTouchStart?: TouchEventHandler;
}

const Link: FunctionComponent<ILinkProps> = (props) => {
    const { className, style, path, children, search, state, onClick, onMouseMove, onMouseOver } = props;
    const to = { pathname: path, search, state };
    return (
        <RouterLink
            className={className}
            style={style}
            to={to}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseOver={onMouseOver}
        >
            {children}
        </RouterLink>
    );
};

export default Link;
