import classnames from 'classnames';
import React, { FunctionComponent, RefObject, useEffect, useRef, useState } from 'react';
import { ISubSequence, ISubSequenceModule } from '../../contentful/contentful-types.d';
import useBackgroundIndex from '../../hooks/useBackgroundIndex';
import useDimensions from '../../hooks/useDimensions';
import HomeLink from '../../routes/Home/HomeLink';
import ProjectLink from '../../routes/Project/ProjectLink';
import { ISubSequenceState } from '../../routes/SubSequence/SubSequencePage';
import CloseButton from '../CloseButton/CloseButton';
import useSubSequence from './hook/useSubSequence';
import styles from './SubSequence.module.scss';
import SubSequenceModule from './SubSequenceModule';

interface props extends ISubSequenceState, ISubSequence {}

const Project: FunctionComponent<props> = (props) => {
    const { referer, page, reversed = false, modules, mobileSpeed = 3 } = props;
    const { screensize, viewHeight } = useDimensions();
    const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    const bgIndex = useBackgroundIndex();
    useEffect(() => ref.current?.focus(), []);
    const { currentPage, onWheel, onTouchStart, onTouchEnd, onTouchMove, onKeyDown } = useSubSequence(
        viewHeight,
        maxHeight,
        mobileSpeed,
    );

    useEffect(() => {
        let max = 0;
        modules.forEach((el: ISubSequenceModule) => {
            const { in: elIn, fadeIn, out: elOut, fadeOut } = el;
            let appears = 0;
            if (elIn !== undefined) {
                appears = fadeIn !== undefined ? elIn + fadeIn : elIn;
            }
            let disappears = null;
            if (elOut !== undefined) {
                disappears = fadeOut !== undefined ? elOut + fadeOut : elOut;
            }
            if (appears > max) {
                max = appears;
            }
            if (disappears && disappears > max) {
                max = disappears;
            }
        });
        setMaxPages(max);
        setMaxHeight(max * viewHeight);
    }, [viewHeight, modules]);

    const className = classnames(styles['SubSequence'], {
        [styles['SubSequence-bg1']]: bgIndex === 1,
        [styles['SubSequence-bg2']]: bgIndex === 2,
        [styles['SubSequence-bg3']]: bgIndex === 3,
        [styles['SubSequence-bg4']]: bgIndex === 4,
    });

    return (
        <div
            ref={ref}
            className={className}
            style={{ height: viewHeight }}
            tabIndex={-1}
            onWheel={onWheel}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onKeyDown={onKeyDown}
        >
            <CloseButton className={styles['SubSequence-closeButton']}>
                {referer ? <ProjectLink path={referer} page={page} /> : <HomeLink />}
            </CloseButton>
            {viewHeight > 0 &&
                modules.map((module, idx) => (
                    <SubSequenceModule
                        key={idx}
                        module={module}
                        currentPage={currentPage}
                        reversed={reversed}
                        maxPages={maxPages}
                        screenSize={screensize}
                    />
                ))}
        </div>
    );
};

export default Project;
