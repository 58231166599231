import {
    useState,
    WheelEvent,
    CSSProperties,
    useEffect,
    useRef,
    TouchEvent,
    KeyboardEventHandler,
    KeyboardEvent,
} from 'react';

import { ITouchEvents, IWheelEvents } from '../../../hooks/types';
import initState, { IUseLoopState } from './initState';
import { touchStart, touchMove, touchEnd } from './dragEvents';
import addWheelOffset from './wheelEvents';
import { handleEndScroll, shouldSnap } from './effects';
import decideStylesByIndex from './decideStylesByIndex';
import handleHeightChange from './handleHeightChange';

export type DecideHeightByItem = (idx: number, viewWidth: number, viewHeight: number) => number;

interface IUseLoopOptions {
    maxPages: number;
    initialPage: number;
    viewWidth: number;
    viewHeight: number;
    decideHeightByItem: DecideHeightByItem;
}

interface IParallaxWrapperProps extends IWheelEvents, ITouchEvents {
    onKeyDown: KeyboardEventHandler;
    tabIndex: number;
}

interface IUseLoop {
    currentPage: number;
    wrapperProps: IParallaxWrapperProps;
    next: () => void;
    prev: () => void;
    getStylesByIndex: (i: number) => CSSProperties | undefined;
}

/**
 * Use loop hook
 * @param renderItem
 * @param maxPages
 */
const useLoop = (options: IUseLoopOptions): IUseLoop => {
    // options
    const { maxPages, initialPage, viewWidth, viewHeight, decideHeightByItem } = options;

    // state
    const [state, setState] = useState<IUseLoopState>(initState(viewHeight, maxPages, initialPage));
    const { currentPage, isSnapping, isScrolling, isTouching, isSnapped, scrolled, dragStart } = state;

    // update height
    useEffect(() => {
        if (viewHeight <= 0) {
            return;
        }
        const decideHeight = (index: number) => decideHeightByItem(index, viewWidth, viewHeight);
        setState((state) => handleHeightChange(viewHeight, state, decideHeight));
    }, [viewWidth, viewHeight, decideHeightByItem]);

    useEffect(() => {
        if (isSnapped || isSnapping) {
            return;
        }
        setState((state) => shouldSnap(state));
    }, [scrolled, isSnapped, isSnapping]);

    // clean up after snap
    const endTimeout = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (isSnapped || isScrolling || isTouching) {
            return;
        }

        if (endTimeout.current) {
            clearTimeout(endTimeout.current);
        }

        endTimeout.current = setTimeout(() => setState((state) => handleEndScroll(state)), 500);

        return () => {
            if (endTimeout.current) {
                clearTimeout(endTimeout.current);
            }
        };
    }, [isSnapped, isScrolling, isTouching, isSnapping, scrolled]);

    // callbacks
    const started = useRef<number>(0);
    const last = useRef<number>(0);
    const onWheel = (e: WheelEvent) => {
        // calculate offsets
        const now = new Date().getTime();
        const diffStart = now - started.current;
        const diffLast = now - last.current;
        // update references
        started.current = diffStart > 1300 ? now : started.current;
        last.current = diffStart > 1300 ? 0 : now;
        if (isSnapping) {
            started.current = now;
            return;
        }

        if (diffLast < 100) {
            return;
        }
        started.current = now;
        setState(addWheelOffset(e.deltaY, state));
    };

    const onTouchStart = (e: TouchEvent) => {
        if (isSnapping) {
            return;
        }
        const newState = touchStart(e.nativeEvent.touches[0].clientY, state);
        if (newState) {
            setState(newState);
        }
    };
    const onTouchMove = (e: TouchEvent) => {
        if (isSnapping || dragStart === null) {
            return;
        }
        const newState = touchMove(e.nativeEvent.touches[0].clientY, state);
        if (newState) {
            setState(newState);
        }
    };
    const onTouchEnd = () => dragStart !== null && setState(touchEnd(state));

    const next = () => {
        if (!isSnapping) {
            setState(addWheelOffset(50, state));
        }
    };
    const prev = () => {
        if (!isSnapping) {
            setState(addWheelOffset(-50, state));
        }
    };

    const onKeyDown = (e: KeyboardEvent) => {
        switch (e.keyCode) {
            case 32:
            case 40:
                next();
                return;
            case 38:
                prev();
                return;
        }
    };

    const getStylesByIndex = (i: number): CSSProperties => decideStylesByIndex(i, state);
    return {
        currentPage,
        wrapperProps: {
            onWheel,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
            onKeyDown,
            tabIndex: 0,
        },
        next,
        prev,
        getStylesByIndex,
    };
};

export default useLoop;
