import classnames from 'classnames';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import SwiperCore, { Keyboard, Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { SwiperOptions } from 'swiper/types';
import { IProjectModuleImage } from '../../contentful/contentful-types.d';
import useDimensions from '../../hooks/useDimensions';
import CloseButton from '../CloseButton/CloseButton';
import styles from './Gallery.module.scss';
import GallerySlide from './GallerySlide';

SwiperCore.use([Navigation, Lazy, Keyboard]);

const swiperConfig: SwiperOptions = {
    navigation: true,
    preloadImages: false,
    lazy: {
        loadPrevNext: true,
    },
    keyboard: {
        enabled: true,
        onlyInViewport: false,
    },
};

interface props {
    className?: string;
    items: IProjectModuleImage[] | null;
    initialPage: number | null;
    closeGallery: () => void;
    onReachEnd: () => void;
    setTags: (tags: string) => void;
}

const Gallery: FC<props> = (props) => {
    const { className, items, initialPage, closeGallery, onReachEnd, setTags } = props;
    const [active, setActive] = useState<number>(initialPage || 0);
    const { screensize } = useDimensions();
    const galleryClass = classnames(className, styles['Gallery']);
    const hashtags: string[] = items
        ? items[active]?.archiveTags.split(/(\s+)/).filter((i) => i.trim().length > 0)
        : [];
    useEffect(() => setActive(initialPage || 0), [initialPage]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const swiper = (document.querySelector('.swiper-container') as any)?.swiper;
    const swiperLength = swiper ? swiper.slides.length : 0;
    const itemsLength = items ? items.length : 0;
    useEffect(() => {
        if (swiperLength < itemsLength) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const swiper = (document.querySelector('.swiper-container') as any)?.swiper;
            swiper.update();
        }
    }, [swiperLength, itemsLength]);

    const handleTagClick = (e: MouseEvent, hashtag: string) => {
        e.preventDefault();
        setTags(hashtag);
        closeGallery();
    };

    return (
        <div className={galleryClass}>
            {items && (
                <>
                    <header>
                        <span className={styles['GallerySpacer']} />
                        <span className={styles['GalleryTags']}>
                            {hashtags.map((hashtag, idx) => (
                                <span key={idx} onClick={(e: MouseEvent) => handleTagClick(e, hashtag)}>
                                    #{hashtag}
                                </span>
                            ))}
                        </span>
                        <CloseButton className={styles['GalleryClose']}>
                            <button type="submit" onClick={closeGallery} />
                        </CloseButton>
                    </header>

                    <Swiper
                        className={styles['GallerySwiper']}
                        initialSlide={initialPage || 0}
                        onReachEnd={onReachEnd}
                        onSlideChange={(swiper) => setActive(swiper.activeIndex)}
                        {...swiperConfig}
                    >
                        {items.map((item, index) => (
                            <SwiperSlide key={`item-${index}`} className={styles['GallerySlide']}>
                                <GallerySlide screenSize={screensize} {...item} />
                                <div
                                    className={styles.GallerySwiperPrev}
                                    onClick={() => {
                                        (document.querySelector('.swiper-container') as any)?.swiper.slidePrev();
                                    }}
                                />
                                <div
                                    className={styles.GallerySwiperNext}
                                    onClick={() => {
                                        (document.querySelector('.swiper-container') as any)?.swiper.slideNext();
                                    }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            )}
        </div>
    );
};

export default Gallery;
