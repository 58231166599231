export enum ScreenSize {
    xl = 'xl',
    lg = 'lg',
    md = 'md',
    // sm = 'sm',
    xs = 'xs',
}

export default function decideScreenSize(availableWidth: number): ScreenSize {
    // if (availableWidth < 415) {
    //     return ScreenSize.xs;
    // }
    if (availableWidth < 768) {
        return ScreenSize.xs;
        // return ScreenSize.sm;
    }
    if (availableWidth < 1024) {
        return ScreenSize.md;
    }
    if (availableWidth < 1366) {
        return ScreenSize.lg;
    }
    return ScreenSize.xl;
}
